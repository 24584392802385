import { createRouter, createWebHistory } from "vue-router";
import Cookies from "js-cookie";

import { isAuthenticated } from "./lib/auth";
import instance from "./lib/api/clientInstance";

import HomePage from "./pages/HomePage.vue";
import MenuPage from "./pages/MenuPage.vue";
import AboutUs from "./pages/AboutUs.vue";
import JobsPage from "./pages/JobsPage.vue";
import ContactPage from "./pages/ContactPage.vue";
import SongPage from "./pages/SongPage.vue";
import BookTable from "./components/BookTable.vue";

import AdminLogin from "./pages/admin/AdminLogin.vue";
import AddCulinary from "./pages/admin/AddCulinary.vue";
import CulinaryList from "./pages/admin/CulinaryList.vue";
import AddImage from "./pages/admin/AddImage.vue";
import ReservationList from "./pages/admin/ReservationList.vue";
import AddJob from "./pages/admin/AddJob.vue";
import JobRequestList from "./pages/admin/JobRequestList.vue";
import CustomerFeedback from "./pages/admin/CustomerFeedback.vue";
import AdminAboutus from "./pages/admin/AdminAboutus.vue";
import MenuHeaderImage from "./pages/admin/MenuHeaderImage.vue";
import KaraokeSongs from "./pages/admin/KaraokeSongs.vue";

// CHAT ROUTES
import ChatLogin from "./pages/chat/ChatLogin.vue";
import ContactsPage from "./pages/chat/ContactsPage.vue";
import ProfilePage from "./pages/chat/ProfilePage.vue";
import ChatsPage from "./pages/chat/ChatsPage.vue";
import MessagePage from "./pages/chat/MessagePage.vue";
import ShowUserDetails from "./pages/chat/ShowUserDetails.vue";

function isInAllowedArea(
  userLat,
  userLng,
  allowedLat,
  allowedLng,
  allowedRadius
) {
  const toRadians = (degrees) => (degrees * Math.PI) / 180;

  const earthRadius = 6371000;
  const dLat = toRadians(allowedLat - userLat);
  const dLng = toRadians(allowedLng - userLng);

  const a =
    Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(toRadians(userLat)) *
      Math.cos(toRadians(allowedLat)) *
      Math.sin(dLng / 2) *
      Math.sin(dLng / 2);

  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  const distance = earthRadius * c;
  return distance <= allowedRadius;
}

const checkLocationAccess = (to, from, next) => {
  if (!navigator.geolocation) {
    console.error("Geolocation is not supported by your browser.");
    next(false);
    return;
  }

  navigator.geolocation.getCurrentPosition(
    (position) => {
      const userLat = position.coords.latitude;
      const userLng = position.coords.longitude;

      //business tower coordinates
      // const allowedLat = 47.91442402563368;
      // const allowedLng = 106.91428029711287;
      const allowedLat = 47.915595;
      const allowedLng = 106.918839;
      const allowedRadius = 50;

      if (
        isInAllowedArea(userLat, userLng, allowedLat, allowedLng, allowedRadius)
      ) {
        next();
      } else {
        alert(
          "Таны одоогийн байршилд энэ хуудас руу нэвтрэх эрхийг хязгаарласан байна."
        );
        next("/");
      }
    },
    (error) => {
      console.error("Geolocation error:", error.message);
      if (error.code === error.PERMISSION_DENIED) {
        alert(
          "Та энэ хуудсыг ашиглахын тулд байршлын мэдээллийг идэвхжүүлэх шаардлагатай. Та вэб хөтчийнхөө тохиргооноос байршлын эрхийг идэвхжүүлнэ үү."
        );
        next("/");
      } else if (error.code === error.POSITION_UNAVAILABLE) {
        alert("Байршлын мэдээллийг авах боломжгүй байна.");
        next(false);
      } else if (error.code === error.TIMEOUT) {
        alert("Байршлыг тодорхойлох хугацаа хэтэрсэн байна.");
        next(false);
      } else {
        alert("Алдаа гарлаа. Дахин оролдоно уу.");
        next(false);
      }
    }
  );
};

const clientRoutes = [
  { path: "/", component: HomePage, meta: { title: "Home" } },
  { path: "/menu/:gate", component: MenuPage, meta: { title: "Menu" } },
  {
    path: "/menu/billiard",
    component: MenuPage,
    meta: { title: "Billiard Club" },
  },
  {
    path: "/book-table",
    component: BookTable,
    meta: { title: "Reservations" },
  },
  { path: "/jobs", component: JobsPage, meta: { title: "Ажлын байр" } },
  { path: "/about", component: AboutUs, meta: { title: "Бидний тухай" } },
  { path: "/contact", component: ContactPage, meta: { title: "Холбоо барих" } },
  {
    path: "/karaoke",
    component: SongPage,
    meta: { title: "Дууны жагсаалт" },
    beforeEnter: checkLocationAccess,
  },
  { path: "/chat/login", component: ChatLogin, meta: { title: "Chat" } },
  { path: "/chat/contacts", component: ContactsPage, meta: { title: "Chat" } },
  { path: "/chat/profile", component: ProfilePage, meta: { title: "Chat" } },
  { path: "/chat/chats", component: ChatsPage, meta: { title: "Chat" } },
  {
    path: "/chat/user-details/:id",
    name: "ShowUserDetails",
    component: ShowUserDetails,
    props: true,
    meta: { title: "Chat" },
  },
  {
    path: "/chat/message/:id",
    name: "MessagePage",
    component: MessagePage,
    props: true,
    meta: { title: "Chat" },
  },
];

const adminRoutes = [
  {
    path: "/admin/login",
    component: AdminLogin,
    meta: { title: "Админ - Нэвтрэх" },
  },
  {
    path: "/admin/add-culinary",
    component: AddCulinary,
    meta: { title: "Админ - Бүтээгдэхүүн нэмэх", requiresAuth: true },
  },
  {
    path: "/admin/culinary-list",
    component: CulinaryList,
    meta: { title: "Админ - Бүтээгдэхүүн жагсаалт", requiresAuth: true },
  },
  {
    path: "/admin/add-image",
    component: AddImage,
    meta: { title: "Админ - Зураг оруулах", requiresAuth: true },
  },
  {
    path: "/admin/reservation-list",
    component: ReservationList,
    meta: { title: "Админ - Ширээ захиалга", requiresAuth: true },
  },
  {
    path: "/admin/add-job",
    component: AddJob,
    meta: { title: "Админ - Ажлын байр нэмэх", requiresAuth: true },
  },
  {
    path: "/admin/add-job/:id",
    component: AddJob,
    meta: { title: "Админ - Ажлын байр нэмэх", requiresAuth: true },
  },
  {
    path: "/admin/job-request-list",
    component: JobRequestList,
    meta: { title: "Админ - Ажлын байр хүсэлт", requiresAuth: true },
  },
  {
    path: "/admin/customer-feedback",
    component: CustomerFeedback,
    meta: { title: "Админ - Санал, хүсэлт", requiresAuth: true },
  },
  {
    path: "/admin/about-us",
    component: AdminAboutus,
    meta: { title: "Админ - Бидний тухай", requiresAuth: true },
  },
  {
    path: "/admin/menu-header-image",
    component: MenuHeaderImage,
    meta: { title: "Админ - Menu Header", requiresAuth: true },
  },
  {
    path: "/admin/karaoke",
    component: KaraokeSongs,
    meta: { title: "Админ - Караоке", requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: [...clientRoutes, ...adminRoutes],
  scrollBehavior(to) {
    if (to.hash) {
      return {
        el: to.hash,
        behavior: "smooth",
      };
    } else {
      return { top: 0 };
    }
  },
});

const sendVisitingInfo = async () => {
  try {
    await instance.post("/web-traffic", {
      created_at: new Date(),
    });
  } catch (error) {
    console.error("Error saving visiting info:", error);
  }
};

// router.beforeEach(async (to, from, next) => {
//   document.title = `GATE | ${to.meta.title}` || "Gate - Concept Bar";

//   if (to.path === "/admin" || to.path === "/admin/") {
//     next("/admin/login");
//     return;
//   }

//   if (!to.path.startsWith("/admin")) {
//     await sendVisitingInfo();
//   }

//   if (to.matched.some((record) => record.meta.requiresAuth)) {
//     const authenticated = await isAuthenticated();
//     if (authenticated) {
//       if (to.path === "/admin/login") {
//         next("/admin/reservation-list");
//         return;
//       }
//       next();
//       return;
//     }

//     next("/admin/login");
//   } else {
//     if (to.path === "/admin/login") {
//       const authenticated = await isAuthenticated();
//       if (authenticated) {
//         next("/admin/reservation-list");
//         return;
//       }
//     }
//     next();
//   }
// });

router.beforeEach(async (to, from, next) => {
  document.title = `GATE | ${to.meta.title}` || "Gate - Concept Bar";

  const chatToken = Cookies.get("GBtoken");

  if (to.path === "/admin" || to.path === "/admin/") {
    next("/admin/login");
    return;
  }

  if (!to.path.startsWith("/admin")) {
    await sendVisitingInfo();
  }

  if (to.path.includes("/chat")) {
    if (to.path === "/chat/login") {
      return chatToken ? next("/chat/profile") : next();
    } else {
      return chatToken ? next() : next("/chat/login");
    }
  }

  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const authenticated = await isAuthenticated();
    if (authenticated) {
      if (to.path === "/admin/login") {
        next("/admin/reservation-list");
        return;
      }
      next();
      return;
    }

    next("/admin/login");
  } else {
    if (to.path === "/admin/login") {
      const authenticated = await isAuthenticated();
      if (authenticated) {
        next("/admin/reservation-list");
        return;
      }
    }
    next();
  }

  next();
});

export default router;
