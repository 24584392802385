<template>
  <div
    class="bg-brand text-gray-700"
    :style="{ minHeight: screenHeight + 'px' }"
  >
    <div class="sm:flex sm:justify-center">
      <!-- Your main content here -->
      <div
        class="w-full sm:w-96 mx-auto bg-white"
        :style="{ height: screenHeight - 70 + 'px' }"
      >
        <!-- h-[calc(100vh-80px)]  -->
        <slot></slot>
      </div>

      <!-- Fixed bottom navigation bar with icons -->
      <footer
        class="bg-gray-50 flex justify-around items-center p-4 fixed bottom-0 left-0 right-0 text-2xl w-full sm:w-96 mx-auto"
      >
        <router-link
          v-for="r in routes"
          :key="r.label"
          :to="r.path"
          :class="[
            $route.path === r.path && 'text-[#f7c104]',
            'flex flex-col justify-center items-center gap-1',
          ]"
        >
          <font-awesome-icon
            :icon="r.icon"
            :class="[$route.path === r.path && 'text-[#f7c104]']"
          />
          <span class="text-sm">{{ r.label }}</span>
        </router-link>
      </footer>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";

export default {
  name: "ChatBoard",
  setup() {
    const isLoading = ref(false);
    const screenHeight = ref(0);

    const routes = [
      { label: "Chats", icon: "message", path: "/chat/chats" },
      { label: "Contacts", icon: "user-group", path: "/chat/contacts" },
      { label: "Me", icon: "user", path: "/chat/profile" },
    ];

    // Function to update the screen height
    const updateHeight = () => {
      screenHeight.value = window.innerHeight;
    };

    onMounted(() => {
      updateHeight();
      window.addEventListener("resize", updateHeight); // Optional: for responsiveness
    });

    return { routes, isLoading, updateHeight, screenHeight };
  },
};
</script>

<style>
html,
body {
  overscroll-behavior: none;
}
</style>
