<template>
  <admin-container>
    <main>
      <h1 class="text-2xl font-bold mb-4">Дууны жагсаалт</h1>
      <div class="flex items-center gap-5 mb-4 w-full">
        <div>
          <label
            for="selectedCategory"
            class="block text-sm font-medium text-gray-700"
            >Хайлтын төрөл сонгох</label
          >
          <select
            name="selectedCategory"
            id="selectedCategory"
            class="mt-1 p-2 bg-gray-200 w-[15rem] border rounded-md focus:ring focus:ring-indigo-300"
            v-model="selectedCategory"
          >
            <option value="" disabled selected>сонгох</option>
            <option :value="1">Дууны нэрээр хайх</option>
            <option :value="2">Хамтлаг, дуучнаар хайх</option>
            <option :value="3">Кодоор хайх</option>
          </select>
        </div>
        <div class="w-1/2">
          <label
            for="searchTerm"
            class="block text-sm font-medium text-gray-700"
            >Хайлтын утга</label
          >
          <input
            type="text"
            name="searchTerm"
            id="searchTerm"
            placeholder="Хайх"
            class="mt-1 p-2 bg-gray-200 w-full border rounded-md focus:ring focus:ring-indigo-300"
            v-model="searchTerm"
            @input="toUppercase()"
          />
        </div>
      </div>

      <div v-if="songs && songs.length > 0 && !isLoading" class="w-full">
        <div class="flex justify-between items-center mb-4 text-sm">
          <div class="space-x-3">
            <span>Хуудас бүрт:</span>
            <select
              name="dataPerPage"
              id="dataPerPage"
              class="border rounded p-1 text-gray-500"
              v-model="dataPerPage"
            >
              <option value="50">50</option>
              <option value="100">100</option>
              <option value="150">150</option>
            </select>
          </div>

          <span>Нийт дууны тоо: {{ totalCount }} </span>

          <div class="flex items-center gap-3">
            <span>Нийт хуудас: {{ totalPages }} </span>
            <PaginationCard
              :totalPages="totalPages"
              :modelValue="currentPage"
              @page-changed="onPageChanged"
            />
          </div>
        </div>
        <hot-table :settings="hotSettings" :data="songs"></hot-table>
      </div>
      <div
        v-else-if="songs && songs.length === 0 && !isLoading"
        class="flex justify-center items-center h-[70vh]"
      >
        Жагсаалт хоосон байна.
      </div>
      <div v-else class="flex justify-center items-center h-[70vh]">
        <SpinLoading />
      </div>

      <h1 class="text-2xl font-bold mt-8 mb-4">
        Дуу нэмэх / засварлах
        <span class="italic text-gray-700 text-sm font-light"
          >* Дууны нэр болон хамтлаг, дуучдын нэрийг ТОМ ҮСГЭЭР бичиж оруулна
          уу.</span
        >
      </h1>
      <section ref="editDiv" class="w-full mb-4 space-y-4">
        <div>
          <label for="songName" class="block text-sm font-medium text-gray-700"
            >Дууны нэр</label
          >
          <input
            name="songName"
            id="songName"
            type="text"
            placeholder="Дууны нэр"
            class="mt-1 p-2 bg-gray-200 w-[50vw] border rounded-md focus:ring focus:ring-indigo-300"
            v-model="form.song_name"
          />
        </div>

        <div class="flex items-center gap-1">
          <input
            name="vocal"
            id="vocal"
            type="checkbox"
            true-value="voc"
            false-value="null"
            class="bg-gray-200 border rounded-md focus:ring focus:ring-indigo-300"
            v-model="form.vocal"
          />
          <label for="vocal" class="block text-sm font-medium text-gray-700"
            >VOC</label
          >
        </div>

        <div>
          <label for="songCode" class="block text-sm font-medium text-gray-700"
            >Дууны код</label
          >
          <input
            name="songCode"
            id="songCode"
            type="text"
            placeholder="Дууны код"
            class="mt-1 p-2 bg-gray-200 w-[50vw] border rounded-md focus:ring focus:ring-indigo-300"
            v-model="form.song_code"
          />
        </div>

        <div>
          <label for="singer" class="block text-sm font-medium text-gray-700"
            >Хамтлаг, дуучид</label
          >
          <input
            name="singer"
            id="singer"
            type="text"
            placeholder="Дуучид"
            class="mt-1 p-2 bg-gray-200 w-[50vw] border rounded-md focus:ring focus:ring-indigo-300"
            v-model="form.singer"
          />
        </div>

        <div class="flex gap-5">
          <button
            type="submit"
            class="flex justify-center items-center gap-1 bg-brand text-white p-2 rounded-md w-40 hover:opacity-90 transition duration-200 ease-in-out"
            @click="uploadSong"
          >
            <font-awesome-icon
              :icon="form.id ? 'fa-solid fa-pen' : 'fa-solid fa-add'"
            />
            {{ form.id ? "Засах" : "Нэмэх" }}
          </button>
          <button
            class="flex justify-center items-center gap-1 bg-gray-500 text-white p-2 rounded-md w-40 hover:opacity-90 transition duration-200 ease-in-out"
            @click="resetForm"
          >
            <font-awesome-icon icon="fa-solid fa-eraser" />
            Арилгах
          </button>
        </div>
      </section>
    </main>

    <error-modal v-if="error" :error="error" />
    <success-modal v-if="success" :success="success" />
  </admin-container>
</template>

<script>
import { onMounted, ref, watch } from "vue";
import AdminContainer from "./AdminContainer.vue";
import adminInstance from "@/lib/api/adminInstance";
import ErrorModal from "@/components/admin/ErrorModal.vue";
import SuccessModal from "@/components/admin/SuccessModal.vue";
import SpinLoading from "@/components/ui/SpinLoading.vue";
import PaginationCard from "@/components/ui/PaginationCard.vue";
import { HotTable } from "@handsontable/vue3";
import { registerAllModules } from "handsontable/registry";
import "handsontable/dist/handsontable.full.css";
import "handsontable/dist/handsontable.full.min.css";

registerAllModules();

export default {
  name: "KaraokeSongs",
  components: {
    AdminContainer,
    HotTable,
    ErrorModal,
    SuccessModal,
    SpinLoading,
    PaginationCard,
  },
  setup() {
    const songs = ref([]);
    const isLoading = ref(false);
    const searchTerm = ref(null);
    const selectedCategory = ref(1);
    const success = ref(null);
    const error = ref(null);
    const form = ref({
      id: null,
      song_name: null,
      vocal: null,
      song_code: null,
      singer: null,
    });

    const dataPerPage = ref(50);
    const totalCount = ref(0);
    const totalPages = ref(1);
    const currentPage = ref(1);

    const fetchSongs = async () => {
      try {
        isLoading.value = true;
        const res = await adminInstance.get(
          `/songs?selectedCategory=${selectedCategory.value}&searchTerm=${searchTerm.value}&page=${currentPage.value}&limit=${dataPerPage.value}`
        );
        if (res.status === 200) {
          songs.value = res.data.result;
          totalPages.value = res.data.totalPages;
          totalCount.value = res.data.total;
        }
      } catch (err) {
        return err;
      } finally {
        isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchSongs();
    });

    watch(searchTerm, () => {
      fetchSongs();
    });

    const commonRenderer = (instance, td, row, col, prop, value) => {
      td.classList.add("htMiddle");
      td.innerHTML = `<span class="flex justify-center w-full p-2 text-black">${
        value ? value : ""
      }</span>`;
      return td;
    };

    const hotSettings = {
      colHeaders: [
        "Үйлдэл",
        "Дууны нэрс",
        "VOC",
        "Дууны код",
        "Хамтлаг, дуучид",
      ],
      colWidths: [100, 400, 100, 100, 400],
      columns: [
        {
          renderer: function (inst, td, row) {
            const song = songs.value[row];
            td.classList.add("htCenter", "htMiddle", "htButton");
            td.innerHTML = `<div class="flex justify-center items-center p-2 gap-2 text-white">
                    <button class="flex justify-center items-center bg-sky-500 p-2 rounded edit-btn">
                        <svg class="fill-current w-3 h-3" viewBox="0 0 512 512"><path d="M362.7 19.3L314.3 67.7 444.3 197.7l48.4-48.4c25-25 25-65.5 0-90.5L453.3 19.3c-25-25-65.5-25-90.5 0zm-71 71L58.6 323.5c-10.4 10.4-18 23.3-22.2 37.4L1 481.2C-1.5 489.7 .8 498.8 7 505s15.3 8.5 23.7 6.1l120.3-35.4c14.1-4.2 27-11.8 37.4-22.2L421.7 220.3 291.7 90.3z"/></svg>
                    </button>
                    <button class="flex justify-center items-center bg-red-500 p-2 rounded delete-btn">
                        <svg class="fill-current w-3 h-3" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><!--!Font Awesome Free 6.5.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license/free Copyright 2024 Fonticons, Inc.--><path d="M135.2 17.7C140.6 6.8 151.7 0 163.8 0H284.2c12.1 0 23.2 6.8 28.6 17.7L320 32h96c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 96 0 81.7 0 64S14.3 32 32 32h96l7.2-14.3zM32 128H416V448c0 35.3-28.7 64-64 64H96c-35.3 0-64-28.7-64-64V128zm96 64c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16zm96 0c-8.8 0-16 7.2-16 16V432c0 8.8 7.2 16 16 16s16-7.2 16-16V208c0-8.8-7.2-16-16-16z"/></svg>
                    </button>
                </div>`;

            const editButton = td.querySelector(".edit-btn");
            if (editButton) {
              editButton.addEventListener("click", () => {
                editSong(song);
              });
            }

            const deleteButton = td.querySelector(".delete-btn");
            if (deleteButton) {
              deleteButton.addEventListener("click", () => {
                deleteSong(song.id, song.song_name);
              });
            }
          },
        },
        {
          data: "song_name",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "vocal",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "song_code",
          readOnly: true,
          renderer: commonRenderer,
        },
        {
          data: "singer",
          readOnly: true,
          renderer: commonRenderer,
        },
      ],
      rowHeaders: true,
      width: "100%",
      height: "70vh",
      fixedColumnsLeft: 1,
      contextMenu: true,
      manualColumnFreeze: true,
      manualColumnMove: true,
      manualColumnResize: true,
      manualRowResize: true,
      manualRowMove: true,
      dropdownMenu: true,
      filters: true,
      columnSorting: true,
      licenseKey: "non-commercial-and-evaluation",
    };

    const editDiv = ref(null);
    const editSong = async (song) => {
      if (song) {
        form.value = {
          id: song.id || null,
          song_name: song.song_name || null,
          vocal: song.vocal || null,
          song_code: song.song_code || null,
          singer: song.singer || null,
        };
      }
      editDiv.value.scrollIntoView({ behavior: "smooth" });
    };

    const deleteSong = async (id, name) => {
      if (!window.confirm(`'${name}' дууг устгах уу?`)) {
        return;
      }

      try {
        const res = await adminInstance.delete(`/delete/song?id=${id}`);
        if (res.status === 200) {
          success.value = "Амжилттай устгалаа.";
          await fetchSongs();
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Устгах явцад алдаа гарлаа";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const uploadSong = async () => {
      try {
        form.value.song_name = form.value.song_name?.toUpperCase() || "";
        form.value.singer = form.value.singer?.toUpperCase() || "";

        let res;

        if (form.value.id) {
          res = await adminInstance.post(`/update/song`, form.value);
        } else {
          res = await adminInstance.post(`/insert/song`, form.value);
        }

        if (res.status === 200) {
          success.value = "Амжилттай шинэчиллээ.";
          resetForm();
          await fetchSongs();
          setTimeout(() => {
            success.value = null;
          }, 3000);
        }
      } catch (err) {
        error.value = "Алдаа гарлаа";
        setTimeout(() => {
          error.value = null;
        }, 3000);
      }
    };

    const toUppercase = () => {
      searchTerm.value = searchTerm.value.toUpperCase();
    };

    const resetForm = () => {
      form.value = {
        id: null,
        song_name: null,
        vocal: null,
        song_code: null,
        singer: null,
      };
    };

    const onPageChanged = (page) => {
      currentPage.value = page;
      fetchSongs();
    };

    return {
      songs,
      isLoading,
      searchTerm,
      selectedCategory,
      hotSettings,
      success,
      error,
      form,
      uploadSong,
      toUppercase,
      editDiv,
      resetForm,
      dataPerPage,
      totalCount,
      totalPages,
      currentPage,
      onPageChanged,
    };
  },
};
</script>
