<template>
  <div
    :style="{ height: `var(--app-height)` }"
    class="sm:flex sm:justify-center bg-brand"
  >
    <div class="flex flex-col h-full sm:w-96 bg-white">
      <div class="bg-gray-50 p-4 shadow-lg flex justify-between items-center">
        <button class="py-1 px-3 rounded-lg" @click="goBack">
          <font-awesome-icon icon="fa-chevron-left" class="text-gray-400" />
        </button>
        <p class="text-center text-xl font-semibold">{{ name }}</p>

        <button class="py-1 px-3 rounded-lg" @click="showFriendDetails">
          <font-awesome-icon icon="fa-ellipsis" class="text-gray-400 text-lg" />
        </button>
      </div>

      <!-- Chat Messages -->
      <div class="p-4 h-full overflow-y-scroll" ref="chatContainer">
        <div
          v-for="(message, index) in messages[chatId]"
          :key="index"
          class="mb-4"
          @click="toggleTimestamp(index)"
        >
          <div
            :class="{
              'flex justify-end': message.sender_id === userToken.id,
              'flex justify-start': message.sender_id !== userToken.id,
            }"
          >
            <div
              :class="{
                'bg-blue-500 text-white py-2 px-4 rounded-2xl inline-block max-w-xs':
                  message.sender_id === userToken.id,
                'bg-gray-200 text-gray-800 py-2 px-4 rounded-2xl inline-block max-w-xs':
                  message.sender_id !== userToken.id,
              }"
            >
              <p
                v-if="selectedMessageIndex === index"
                :class="[
                  'text-sm italic mb-2',
                  message.sender_id === userToken.id
                    ? 'text-gray-200'
                    : 'text-gray-500',
                ]"
              >
                {{ formatTime(message.sent_at) }}
              </p>

              <p class="text-lg">{{ message.message }}</p>
            </div>
          </div>
        </div>
      </div>

      <!-- Message Input and Emoji Picker -->
      <div class="flex items-center space-x-1 p-4 relative bg-gray-50">
        <input
          v-model="newMessage"
          @keydown.enter="sendMessage"
          type="text"
          placeholder="Type a message"
          class="flex-1 border rounded-2xl px-3 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />

        <button class="text-gray-400 px-2 py-1 rounded-2xl hover:text-blue-500">
          <font-awesome-icon icon="fa-smile" class="h-4 w-4" />
        </button>

        <button
          class="text-gray-400 px-2 py-1 rounded-2xl hover:text-blue-500"
          @click="sendMessage"
        >
          <font-awesome-icon icon="fa-paper-plane" class="h-4 w-4" />
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, onUnmounted, watch, nextTick } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "MessagePage",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const chatId = route.params.id;
    const name = route.query.name || "";

    const messages = computed(() => store.getters["chat/getMessages"]);
    const userToken = computed(() => store.getters["chat/getUser"]);

    const selectedMessageIndex = ref(null);
    const newMessage = ref("");
    const chatContainer = ref(null);
    const receiverId = ref("");

    onMounted(() => {
      adjustAppHeight();
      window.addEventListener("resize", adjustAppHeight);

      fetchChatIdAndMessages(chatId);

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.addEventListener("message", (event) => {
          const message = event.data.payload;

          // Handle the message data
          if (message) {
            const handleNewMessage = (chatId) => {
              if (!messages.value[chatId]) messages.value[chatId] = [];

              // Check if the message already exists using message_id
              const existingMessage = messages.value[chatId].find(
                (msg) => msg.message_id === message.body.message_id
              );

              if (!existingMessage) {
                messages.value[chatId].push(message.body);
                scrollToBottom();
              }
            };

            if (
              route.name === "MessagePage" &&
              route.params.id == message?.body?.chat_id
            ) {
              handleNewMessage(message.body.chat_id);

              store.dispatch("chat/markMessagesAsSeen", {
                chatId: message.body.chat_id,
                senderId: message.body.sender_id,
                msgId: message.body.message_id,
              });
            } else {
              handleNewMessage(message.body.chat_id);
            }
          }
        });
      }
    });

    const fetchChatIdAndMessages = async (chatId) => {
      try {
        const res = await store.dispatch("chat/fetchMessagesByChatId", {
          chatId: chatId,
          myId: userToken.value.id,
        });

        receiverId.value = res.receiverId;
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const adjustAppHeight = () => {
      const appHeight = window.innerHeight + "px";
      document.documentElement.style.setProperty("--app-height", appHeight);
    };

    // Toggle timestamp visibility
    const toggleTimestamp = (index) => {
      selectedMessageIndex.value =
        selectedMessageIndex.value === index ? null : index; // Toggle same index or reset
    };

    const formatTime = (timestamp) => {
      if (!timestamp) return "Invalid time";

      try {
        const date = new Date(timestamp);
        if (isNaN(date)) throw new Error();

        const now = new Date();
        const isToday =
          date.getDate() === now.getDate() &&
          date.getMonth() === now.getMonth() &&
          date.getFullYear() === now.getFullYear();

        if (isToday) {
          // Show only the time if the message is from today
          return new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(date);
        } else {
          // Show full date and time if the message is from another day
          return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(date);
        }
      } catch {
        return "Invalid time";
      }
    };

    const scrollToBottom = () => {
      nextTick(() => {
        if (chatContainer.value) {
          chatContainer.value.scrollTop = chatContainer.value.scrollHeight;
        }
      });
    };

    const sendMessage = async () => {
      if (newMessage.value.trim()) {
        const message = {
          message: newMessage.value.trim(),
          senderId: userToken?.value?.id,
          chatId: chatId,
        };

        const res = await store.dispatch("chat/sendMessage", message);

        if (!res.error) {
          messages.value[chatId].push(res.sentMsg);

          newMessage.value = "";
        } else {
          alert("Message илгээхэд алдаа гарлаа.");
        }
        scrollToBottom();
      }
    };

    // Watch the messages for changes
    watch(
      messages,
      (newMessages) => {
        if (newMessages && chatId && newMessages[chatId]) {
          scrollToBottom(); // Scroll to the bottom of the chat
        }
      },
      { deep: true, immediate: true } // Add options for deep watching and immediate execution
    );

    watch(
      () => messages.value[chatId],
      () => {
        scrollToBottom(); // Scroll whenever the chat updates
      },
      { immediate: true, deep: true }
    );

    onUnmounted(() => {
      // if ("serviceWorker" in navigator) {
      //   navigator.serviceWorker.removeEventListener(
      //     "message",
      //     handleServiceWorkerMessage
      //   );
      // }

      window.removeEventListener("resize", adjustAppHeight);
    });

    const goBack = () => {
      router.go(-1);
    };

    const showFriendDetails = () => {
      router.push({
        name: "ShowUserDetails",
        params: { id: receiverId.value },
        // query: { name: user.username },
      });
    };

    return {
      chatId,
      name,
      goBack,
      messages,
      userToken,
      newMessage,
      formatTime,
      sendMessage,
      chatContainer,
      toggleTimestamp,
      showFriendDetails,
      selectedMessageIndex,
    };
  },
};
</script>

<style scoped>
.main-body {
  height: calc(100vh - 80px);
}

.overflow-y-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
</style>
