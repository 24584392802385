import instance from "@/lib/api/clientInstance";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";

export default {
  namespaced: true,
  state() {
    return {
      token: null,
      user: {},
      messages: {},
      friends: [],
      myChats: [],
      isShowChatBtn: JSON.parse(localStorage.getItem("isShowChatBtn")) || false,
    };
  },

  getters: {
    getUser(state) {
      return state.user;
    },
    getFriends(state) {
      return state.friends;
    },
    getMessages(state) {
      return state.messages;
    },
    getChats(state) {
      return state.myChats;
    },
    getChatBtn(state) {
      return state.isShowChatBtn;
    },
  },

  mutations: {
    SET_TOKEN(state, token) {
      state.token = token;
    },
    SET_USER(state, user) {
      state.user = user;
    },
    setFriends(state, payload) {
      state.friends = payload;
    },
    setChats(state, payload) {
      state.myChats = payload;
    },
    addMessage(state, { chatId, body }) {
      if (!state.messages[chatId]) {
        state.messages[chatId] = [];
      }

      const newMessages = Array.isArray(body) ? body : [body];
      state.messages[chatId] = [
        ...state.messages[chatId],
        ...newMessages.filter(
          (msg) =>
            !state.messages[chatId].some(
              (existingMsg) => existingMsg.id === msg.id
            )
        ),
      ];
    },
    setChatBtn(state, value) {
      state.isShowChatBtn = value;
      localStorage.setItem("isShowChatBtn", JSON.stringify(value));
    },
  },

  actions: {
    // SUBSCRIPTION START <---------------->
    async fetchisValid(_, payload) {
      try {
        const res = await instance.post("/chat/validateSubscription", payload);
        return res.data.valid;
      } catch (error) {
        console.error("Error validating subscription:", error);
        throw error;
      }
    },

    async fetchVapidPublicKey() {
      try {
        const res = await instance.get("/chat/vapidPublicKey");
        return res.data.publicKey;
      } catch (error) {
        console.error("Error fetching friend details:", error);
        throw error;
      }
    },

    async registerSubscription(_, payload) {
      try {
        const res = await instance.post("/chat/subscribe", payload);
        return res.data;
      } catch (error) {
        console.error("Error fetching friend details:", error);
        throw error;
      }
    },
    // SUBSCRIPTION END <---------------->

    async login(_, payload) {
      try {
        const res = await instance.post("/chat/login", payload);
        return res.data;
      } catch (error) {
        console.error(error);
        throw error;
      }
    },

    initializeToken({ commit }) {
      const token = Cookies.get("GBtoken");
      if (token) {
        commit("SET_TOKEN", token);
        commit("SET_USER", jwtDecode(token));
      }
    },

    clearToken({ commit }) {
      Cookies.remove("GBtoken");
      commit("SET_TOKEN", null);
      commit("SET_USER", {});
    },

    async fetchFriends({ commit }, payload) {
      try {
        const res = await instance.post("/chat/getFriends", payload);

        commit("setFriends", res.data.friends);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchProfile(_, payload) {
      try {
        const res = await instance.post("/chat/getProfile", payload);

        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async updateProfile(_, payload) {
      try {
        const res = await instance.post("/chat/updateProfileByEmail", payload);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchChats({ commit }, payload) {
      try {
        const res = await instance.post("/chat/getChats", payload);

        commit("setChats", res.data.chats);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchOrCreateChat(_, payload) {
      try {
        const res = await instance.post("/chat/getOrCreateChat", payload);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async fetchMessagesByChatId({ commit }, payload) {
      try {
        const res = await instance.post("/chat/messages", payload);

        commit("addMessage", {
          chatId: res?.data?.chatId,
          body: res?.data?.messages,
        });

        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    async sendMessage(_, payload) {
      try {
        const res = await instance.post("/chat/send-messages", payload);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },

    toggleChatBtn({ commit }, payload) {
      commit("setChatBtn", payload); // Action to toggle button visibility
    },

    async markMessagesAsSeen(_, payload) {
      try {
        const res = await instance.post("/chat/markMessagesAsSeen", payload);
        return res.data;
      } catch (error) {
        console.error(error);
      }
    },
  },
};
