<template>
  <ChatContainer>
    <div
      class="p-4 bg-gray-50 fixed top-0 left-0 right-0 w-full sm:w-96 mx-auto"
    >
      <div class="relative z-10">
        <div class="flex justify-between items-center" v-if="!isProfileVisible">
          <button
            @click="cancelBtn"
            class="border border-gray-200 py-1 px-3 rounded-lg absolute"
          >
            Cancel
          </button>

          <p class="text-center text-xl font-semibold mx-auto">
            {{ updatingProfile.title }}
          </p>

          <button
            :class="[
              isProfileChanged
                ? 'bg-green-600 text-white font-bold'
                : 'bg-gray-50 text-gray-500',
              'border border-gray-200 py-1 px-3 rounded-lg absolute right-0',
            ]"
            :disabled="!isProfileChanged"
            @click="updateProfile"
          >
            Done
          </button>
        </div>

        <div v-else>
          <button
            v-if="isProfileVisible"
            class="p-0.5 rounded-lg absolute border border-[#f7c104] flex justify-center items-center gap-1 text-sm"
            @click="goBackToMenu"
          >
            <font-awesome-icon icon="fa-utensils" class="text-[#f7c104]" />
            <span class="bg-[#f7c104] px-1 rounded-lg text-white">Menu</span>
          </button>

          <p class="text-center text-xl font-semibold mx-auto">
            {{ updatingProfile.title ? updatingProfile.title : "My Profile" }}
          </p>
        </div>
      </div>
    </div>

    <div
      class="h-full overflow-y-scroll scrollbar-hide overflow-x-hidden pt-16 p-4"
    >
      <!-- h-[calc(100vh-80px)] -->
      <div v-if="isProfileVisible">
        <div
          v-for="(value, key) in profile"
          :key="key"
          :class="[
            key === 'email' ? 'text-gray-500' : 'hover:bg-gray-50',
            'flex justify-between items-center border-b py-4 ',
          ]"
          @click="key !== 'email' && toggleProfileView(value, key)"
        >
          <p>{{ formatLabel(key) }}</p>
          <div class="flex gap-3 items-center">
            <p v-if="key !== 'profile_picture'">{{ value }}</p>
            <img v-else :src="value" class="h-16 w-16" />
            <font-awesome-icon
              icon="fa-chevron-right"
              class="text-gray-400"
              v-if="key !== 'email'"
            />
          </div>
        </div>

        <div class="bg-gray-100 border shadow-lg rounded-lg p-4 mt-4">
          <div class="flex items-center gap-3 mb-6 text-red-500">
            <font-awesome-icon icon="fa-circle-exclamation" class="text-xl" />
            <p>Gatebar чат ашиглах заавар</p>
          </div>

          <div>
            <!-- Android Button -->
            <button
              class="flex justify-between items-center w-full mb-4"
              @click="toggleAndroid"
            >
              <p class="font-bold underline text-lg">Android</p>
              <font-awesome-icon
                :icon="!isAndroidVisible ? 'fa-chevron-up' : 'fa-chevron-down'"
              />
            </button>

            <!-- Android Content -->
            <div v-if="isAndroidVisible" class="android my-4 space-y-6">
              <p class="text-justify my-1">
                Алхам 1 : Та өөрт ирж байгаа чатаа цаг тухайд нь хүлээж авахын
                тулд subscribe товчийг дарна.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/subscriptionBtn.png"
                alt=""
                class="h-sm w-full object-cover"
              />

              <p class="text-justify my-1">
                Алхам 2 : Дараа нь Allow товчийг дарна уу.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/androidNotification.png"
                alt=""
                class="h-sm w-full object-cover"
              />
            </div>

            <!-- iPhone Button -->
            <button
              class="flex justify-between items-center w-full"
              @click="toggleIphone"
            >
              <p class="font-bold underline text-lg">iPhone</p>

              <font-awesome-icon
                :icon="!isIphoneVisible ? 'fa-chevron-up' : 'fa-chevron-down'"
              />
            </button>

            <!-- iPhone Content -->
            <div v-if="isIphoneVisible" class="ios space-y-6">
              <p class="text-justify my-1">
                Алхам 1 : Та өөрт ирж байгаа чатаа цаг тухайд нь хүлээж авахын
                тулд gatebar.mn луу нэвтрэн ороод браузерынхаа
                <span class="bg-gray-700 p-1 rounded-lg">Share</span> btn дарна.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/iosShareBtn.png"
                alt=""
                class="h-sm w-full object-cover"
              />

              <p class="text-justify my-1">
                Алхам 2 : Гарч ирсэн нүүр нь дээрээс
                <span class="bg-gray-700 p-1 rounded-lg"
                  >Add to Home Screen</span
                >
                хэсгийг сонгон дарна.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/iosAddToScreen.png"
                alt=""
                class="h-sm w-full object-cover"
              />

              <p class="text-justify my-1">
                Алхам 3 : Web аппдаа нэр өгөн Add дарна.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/iosAddApp.png"
                alt=""
                class="h-sm w-full object-cover"
              />

              <p class="text-justify my-1">
                Алхам 3 : Таны утсан дээр Gatebar logo тай шар өнгийн Web апп
                сууна.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/iosApp.png"
                alt=""
                class="h-sm w-full object-cover"
              />

              <p class="text-justify my-1">
                Алхам 4 : Та өөрт ирж байгаа чатаа цаг тухайд нь хүлээж авахын
                тулд утсанд нэмсэн Gate web app руугаа дарж орон subscribe
                товчийг дарна.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/subscriptionBtn.png"
                alt=""
                class="h-sm w-full object-cover"
              />

              <p class="text-justify my-1">
                Алхам 5 : Дараа нь Allow товчийг дарна уу.
              </p>

              <img
                src="https://gatebar.mn/static/chatUsageGuide/iosNotification.png"
                alt=""
                class="h-sm w-full object-cover"
              />
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        <div
          class="relative mt-6"
          v-if="updatingProfile.title !== 'profile_picture'"
        >
          <div
            class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
          >
            <font-awesome-icon icon="fa-star" class="h-3 w-3 text-amber-500" />
          </div>

          <input
            type="search"
            v-model="updatingProfile.value"
            id="default-search"
            class="block w-full sm:w-[350px] p-2 ps-10 text-gray-900 rounded-lg bg-gray-50 border border-gray-100"
            required
          />
        </div>

        <div v-else class="relative">
          <button
            @click="openFileInput"
            class="bg-gray-100 flex justify-center items-center gap-3 py-1 px-3 absolute top-4 right-3 rounded-lg"
          >
            <font-awesome-icon icon="fa-pen" class="h-4 w-4 text-gray-500" />

            Update profile picture
          </button>

          <input
            type="file"
            ref="fileInput"
            style="display: none"
            @change="handleFileUpload"
          />

          <img
            :src="updatingProfile.value"
            alt=""
            class="w-full h-96 flex justify-center items-center object-fill"
          />
        </div>
      </div>
    </div>
  </ChatContainer>
</template>

<script>
import { ref, onMounted, computed, reactive, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ProfilePage",
  setup() {
    const store = useStore();
    const router = useRouter();

    const profile = ref({});
    const isProfileVisible = ref(true);
    const isProfileChanged = ref(false);

    const fileInput = ref(null);
    const profileImgFile = ref(null);

    const updatingProfile = reactive({
      title: "",
      value: "",
    });

    const userToken = computed(() => store.getters["chat/getUser"]);

    const fetchProfileInfo = async () => {
      try {
        // isLoading.value = true;
        const res = await store.dispatch("chat/fetchProfile", userToken.value);
        profile.value = res.profile;
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        // isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchProfileInfo();
    });

    const formatLabel = (key) => {
      const labels = {
        username: "Full Name",
        display_name: "User Name",
        email: "Email",
        relationship_status: "Relationship Status",
        table_number: "Table Number",
        profile_picture: "Profile Picture",
      };
      return labels[key] || key;
    };

    const toggleProfileView = (value, key) => {
      updatingProfile.value = value ? value : "";
      updatingProfile.title = key;

      isProfileVisible.value = !isProfileVisible.value;
      if (!isProfileVisible.value) {
        fetchProfileInfo();
      }
    };

    watch(
      () => updatingProfile.value,
      (newValue, oldValue) => {
        if (oldValue && newValue && newValue !== oldValue) {
          isProfileChanged.value = true;
        }
      }
    );

    const cancelBtn = () => {
      isProfileChanged.value = false;
      isProfileVisible.value = true;
      updatingProfile.title = "";
      updatingProfile.value = "";
      profileImgFile.value = null;
    };

    const openFileInput = () => {
      fileInput.value.click();
    };

    const handleFileUpload = (event) => {
      const file = event.target.files[0];
      profileImgFile.value = file;
      if (file) {
        updatingProfile.value = URL.createObjectURL(file);
      }
    };

    const updateProfile = async () => {
      try {
        const formData = new FormData();

        formData.append("email", profile.value.email);
        formData.append("key", updatingProfile.title);

        if (updatingProfile.title == "profile_picture") {
          formData.append("file", profileImgFile.value);
        } else {
          formData.append("value", updatingProfile.value);
        }

        const res = await store.dispatch("chat/updateProfile", formData);

        if (res.message == "Амжилттай") {
          isProfileVisible.value = true;
          fetchProfileInfo();

          isProfileChanged.value = false;
          updatingProfile.title = "";
          updatingProfile.value = "";
          profileImgFile.value = null;
        }
      } catch (error) {
        console.error(error);
        throw error;
      }
    };

    const goBackToMenu = () => {
      router.push({ path: "/", hash: "#menu" });
    };

    const isAndroidVisible = ref(false);
    const isIphoneVisible = ref(false);

    // Toggle visibility for Android
    const toggleAndroid = () => {
      isAndroidVisible.value = !isAndroidVisible.value;
      if (isIphoneVisible.value) isIphoneVisible.value = false;
    };

    // Toggle visibility for iPhone
    const toggleIphone = () => {
      isIphoneVisible.value = !isIphoneVisible.value;
      if (isAndroidVisible.value) isAndroidVisible.value = false;
    };

    return {
      profile,
      toggleProfileView,
      isProfileVisible,
      formatLabel,
      updatingProfile,
      isProfileChanged,
      updateProfile,
      cancelBtn,
      openFileInput,
      handleFileUpload,
      fileInput,
      goBackToMenu,
      isAndroidVisible,
      isIphoneVisible,
      toggleAndroid,
      toggleIphone,
    };
  },
};
</script>
