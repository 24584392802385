import { createApp } from "vue";

import App from "./App.vue";
import router from "./router";
import store from "./store";

import "./index.css";
import TheNav from "./components/ui/TheNav.vue";
import TheFooter from "./components/ui/TheFooter.vue";
import AdminContainer from "./pages/admin/AdminContainer.vue";
import ChatContainer from "./pages/chat/ChatContainer.vue";
import "animate.css";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import "leaflet/dist/leaflet.css";

import CKEditor from "@ckeditor/ckeditor5-vue";

import "vue3-emoji-picker/css";

const app = createApp(App);

app.use(router);
app.use(store);
app.use(CKEditor);

app.component("WebNav", TheNav);
app.component("WebFooter", TheFooter);
app.component("AdminContainer", AdminContainer);
app.component("ChatContainer", ChatContainer);

library.add(faUserSecret, fas, fab, far);
app.component("font-awesome-icon", FontAwesomeIcon);

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/service-worker.js") // Path to your service worker file
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })

    .catch((error) => {
      console.error("Service Worker registration failed:", error);
    });
}

app.mount("#app");
