<template>
  <AskPermissionAlert
    v-if="shouldShowNotification"
    :showAlert="showAlert"
    @update:showAlert="showAlert = $event"
    @subscribe="onSubscribe"
  />

  <div
    class="fixed right-6 bottom-20 bg-blue-500 shadow-2xl rounded-full p-4 w-12 h-12 z-10 flex justify-center items-center"
    v-if="isShowIcon"
    @click="toggleChat"
  >
    <font-awesome-icon icon="fa-comments" class="text-white text-lg" />
  </div>

  <router-view></router-view>
</template>

<script>
import { ref, watch, computed, onMounted } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";

import AskPermissionAlert from "./pages/chat/AskPermissionAlert.vue";

export default {
  name: "App",
  components: { AskPermissionAlert },

  setup() {
    const router = useRouter();
    const store = useStore();
    const route = useRoute();

    const showAlert = ref(true); // Initially show the alert
    const hasSubscription = ref(false);
    const isSubscriptionValid = ref(false);

    const isShowIcon = ref(store.getters["chat/getChatBtn"]);
    const userToken = computed(() => store.getters["chat/getUser"]);

    // watch(
    //   () => route.path,
    //   async (newRoute) => {
    //     if (newRoute == "/menu/gate-5") {
    //       const registration = await navigator.serviceWorker.ready;
    //       const subscription = await registration.pushManager.getSubscription();
    //       hasSubscription.value = subscription !== null;

    //       if (hasSubscription.value) {
    //         const data = {
    //           device_id: generateDeviceId(),
    //           endpoint: subscription.endpoint,
    //           user_id: userToken.value.id,
    //         };

    //         // If the subscription exists, validate it
    //         const isValid = await store.dispatch("chat/fetchisValid", data);

    //         isSubscriptionValid.value = isValid;
    //       } else {
    //         isSubscriptionValid.value = false; // If there's no subscription, it's not valid
    //       }
    //     }
    //   }
    // );

    watch(
      () => route.path,
      async (newRoute) => {
        if (newRoute.includes("chat")) {
          const registration = await navigator.serviceWorker.ready;
          const subscription = await registration.pushManager.getSubscription();
          hasSubscription.value = subscription !== null;

          if (hasSubscription.value) {
            const data = {
              device_id: generateDeviceId(),
              endpoint: subscription.endpoint,
              user_id: userToken.value.id,
            };

            // If the subscription exists, validate it
            isSubscriptionValid.value = await store.dispatch(
              "chat/fetchisValid",
              data
            );
          } else {
            isSubscriptionValid.value = false; // If there's no subscription, it's not valid
          }
        }

        isShowIcon.value = newRoute == "/menu/gate-5" ? true : false;
        // store.dispatch("chat/toggleChatBtn", true);
      },
      { immediate: true }
    );

    onMounted(async () => {
      await store.dispatch("chat/initializeToken");
    });

    // // Logic to control when the alert should show
    const shouldShowNotification = computed(() => {
      return (
        route.path.includes("chat") &&
        route.path !== "/chat/login" &&
        (!hasSubscription.value || !isSubscriptionValid.value) // Show alert if subscription doesn't exist or is invalid
      );
    });

    const urlBase64ToUint8Array = (base64String) => {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      const base64 = (base64String + padding)
        .replace(/-/g, "+")
        .replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);
      for (let i = 0; i < rawData.length; i++) {
        outputArray[i] = rawData.charCodeAt(i);
      }
      return outputArray;
    };

    const requestPermission = async () => {
      if (!("Notification" in window)) {
        alert("This browser does not support desktop notification");
      }

      const permission = await Notification.requestPermission();

      if (permission !== "granted") {
        alert("Permission denied for push notifications");
        return false;
      }

      return true;
    };

    const getShortDeviceInfo = () => {
      const userAgent = navigator.userAgent;
      const platform = navigator.platform;

      // Extract OS information
      const osMatch = userAgent.match(/\(([^)]+)\)/);
      const os = osMatch ? osMatch[1].split(";")[0] : "Unknown OS";

      // Extract browser information
      let browser = "Unknown Browser";
      if (userAgent.includes("Chrome")) {
        browser = "Chrome";
      } else if (
        userAgent.includes("Safari") &&
        !userAgent.includes("Chrome")
      ) {
        browser = "Safari";
      } else if (userAgent.includes("Firefox")) {
        browser = "Firefox";
      } else if (userAgent.includes("Edge")) {
        browser = "Edge";
      }

      return `${platform} - ${os} - ${browser}`;
    };

    const generateDeviceId = () => {
      const deviceInfo = `${navigator.platform} - ${navigator.userAgent}`;
      const hash = btoa(deviceInfo).slice(0, 16); // Encode and shorten to 16 characters
      return hash;
    };

    // Handler for the subscribe action
    const onSubscribe = async () => {
      try {
        const permissionGranted = await requestPermission();

        if (!permissionGranted) return;

        const publicKey = await store.dispatch("chat/fetchVapidPublicKey");

        const registration = await navigator.serviceWorker.ready;

        let subscription = await registration.pushManager.getSubscription();

        if (!subscription) {
          subscription = await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: urlBase64ToUint8Array(publicKey),
          });

          await store.dispatch("chat/registerSubscription", {
            subscription,
            device_info: getShortDeviceInfo(),
            device_id: generateDeviceId(),
            user_id: userToken.value.id,
          });

          console.log("Subscribed to push notifications!");
        } else {
          const isValid = await store.dispatch("chat/fetchisValid", {
            device_id: generateDeviceId(),
            endpoint: subscription.endpoint,
            user_id: userToken.value.id,
          });

          if (!isValid) {
            await subscription.unsubscribe();
            subscription = await registration.pushManager.subscribe({
              userVisibleOnly: true,
              applicationServerKey: urlBase64ToUint8Array(publicKey),
            });

            await store.dispatch("chat/registerSubscription", {
              subscription,
              device_info: getShortDeviceInfo(),
              device_id: generateDeviceId(),
              user_id: userToken.value.id,
            });

            console.log("Subscribed to push notifications!");
          } else {
            console.log("Using existing valid subscription.");
          }
        }
      } catch (error) {
        console.error("Error subscribing to push notifications:", error);
        alert(error);
      }
    };

    const toggleChat = () => {
      router.push({ path: "/chat/chats" });
    };

    return {
      shouldShowNotification,
      onSubscribe,
      showAlert,
      isShowIcon,
      toggleChat,
    };
  },
};
</script>
