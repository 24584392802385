<template>
  <ChatContainer>
    <div
      class="p-4 bg-gray-50 fixed top-0 left-0 right-0 w-full sm:w-96 mx-auto"
    >
      <button
        class="p-0.5 rounded-lg absolute border border-[#f7c104] flex justify-center items-center gap-1 text-sm"
        @click="goBackToMenu"
      >
        <font-awesome-icon icon="fa-utensils" class="text-[#f7c104]" />
        <span class="bg-[#f7c104] px-1 rounded-lg text-white">Menu</span>
      </button>

      <p class="text-center text-xl mb-4 font-semibold">
        Contacts
        <span v-if="friends.length">( {{ friends.length }} )</span>
      </p>

      <div class="relative">
        <div
          class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
        >
          <font-awesome-icon icon="fa-search" class="h-4 w-4 text-gray-400" />
        </div>

        <input
          type="search"
          id="default-search"
          v-model="searchUser"
          class="block w-full p-2 ps-10 text-gray-900 rounded-lg bg-white border border-gray-100"
          placeholder="Search..."
          autocomplete="off"
          required
        />
      </div>
    </div>

    <div
      class="h-full overflow-y-scroll scrollbar-hide overflow-x-hidden pt-32 pb-3"
    >
      <!-- h-[calc(100vh-80px)] -->
      <ul v-for="(group, letter) in groupedFriends" :key="letter">
        <li class="font-bold text-base px-6 pt-1 text-gray-400">
          {{ letter }}
        </li>
        <li
          v-for="friend in group"
          :key="friend.id"
          class="flex items-center justify-between border-b border-gray-100 px-4 py-2 cursor-pointer hover:bg-gray-100 text-lg"
          @click="selectFriend(friend)"
        >
          <div class="flex items-center w-full gap-4">
            <img
              :src="friend.profile_picture"
              alt="Avatar"
              class="w-9 h-9 rounded-lg"
              v-if="friend.profile_picture"
            />
            <div
              v-else
              class="w-9 h-9 rounded-full bg-gray-200 flex justify-center items-center"
            >
              {{ friend.email ? friend.email.charAt(0) : friend.id }}
            </div>

            <div class="w-full space-y-1">
              <div class="flex flex-col justify-center">
                <span>
                  {{
                    friend.display_name ? friend.display_name : friend.username
                  }}</span
                >
                <p class="text-base text-gray-500">
                  Ширээний дугаар :
                  {{ friend.table_number ? friend.table_number : "оруулаагүй" }}
                </p>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </ChatContainer>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ContactsPage",
  setup() {
    const store = useStore();
    const router = useRouter();
    const searchUser = ref("");

    const userToken = computed(() => store.getters["chat/getUser"]);
    const friends = computed(() => store.getters["chat/getFriends"]);

    const fetchFriends = async (newSearchTerm) => {
      try {
        // isLoading.value = true;
        await store.dispatch("chat/fetchFriends", {
          email: userToken.value.email,
          searchUser: newSearchTerm,
        });
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        // isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchFriends();
    });

    // Group friends alphabetically by their first letter
    const groupedFriends = computed(() => {
      const groups = {};
      friends.value.forEach((friend) => {
        const firstLetter = friend.username.charAt(0).toUpperCase();
        if (!groups[firstLetter]) {
          groups[firstLetter] = [];
        }
        groups[firstLetter].push(friend);
      });
      return groups;
    });

    watch(searchUser, (newSearchTerm) => {
      fetchFriends(newSearchTerm);
    });

    const selectFriend = async (user) => {
      try {
        const res = await store.dispatch("chat/fetchOrCreateChat", {
          myId: userToken.value.id,
          friendId: user.id,
        });

        if (res.message == "success") {
          router.push({
            name: "MessagePage",
            params: { id: res?.chatId },
            query: { name: user.username },
          });
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const goBackToMenu = () => {
      router.push({ path: "/", hash: "#menu" });
    };

    return { groupedFriends, selectFriend, searchUser, friends, goBackToMenu };
  },
};
</script>

<style>
.overflow-y-scroll {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.overflow-y-scroll::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}
</style>
