<template>
  <ChatContainer>
    <div
      class="p-4 bg-gray-50 fixed top-0 left-0 right-0 z-10 w-full sm:w-96 mx-auto"
    >
      <button
        class="p-0.5 rounded-lg absolute border border-[#f7c104] flex justify-center items-center gap-1 text-sm"
        @click="goBackToMenu"
      >
        <font-awesome-icon icon="fa-utensils" class="text-[#f7c104]" />
        <span class="bg-[#f7c104] px-1 rounded-lg text-white">Menu</span>
      </button>

      <p class="text-center text-xl mb-4 font-bold">
        Chats <span v-if="chats.length">( {{ chats.length }} )</span>
      </p>

      <div class="relative">
        <div
          class="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none"
        >
          <font-awesome-icon icon="fa-search" class="h-4 w-4 text-gray-400" />
        </div>

        <input
          type="search"
          id="default-search"
          v-model="searchValue"
          class="block w-full p-2 ps-10 text-gray-900 rounded-lg bg-white border border-gray-100"
          placeholder="Search..."
          autocomplete="off"
          required
        />
      </div>
    </div>

    <div
      class="h-full overflow-y-scroll scrollbar-hide overflow-x-hidden pt-32 pb-4"
    >
      <!-- h-[calc(100vh-80px)] -->
      <ul v-if="chats.length > 0">
        <li
          v-for="chat in chats"
          :key="chat.chat_id"
          class="border-b px-4 py-3 cursor-pointer hover:bg-gray-100 grid grid-cols-8 items-center gap-3 w-full"
          @click="selectChat(chat)"
        >
          <div class="col-span-1 relative">
            <img
              :src="chat.friend_profile_picture"
              alt="Avatar"
              class="w-9 h-9 rounded-lg"
              v-if="chat.friend_profile_picture"
            />
            <div
              v-else
              class="w-9 h-9 rounded-full bg-gray-200 flex justify-center items-center col-span-7"
            >
              {{
                chat.friend_email ? chat.friend_email.charAt(0) : chat.chat_id
              }}
            </div>

            <div
              class="absolute -top-1 right-0 transform translate-x-1 -translate-y-1 z-10 bg-red-500 rounded-full text-white px-2 py-0.5 text-xs font-bold flex justify-center items-center"
              v-if="chat.unread_count !== '0'"
            >
              <span>{{ chat.unread_count }}</span>
            </div>
          </div>

          <div class="space-y-1 w-full col-span-7">
            <div class="flex justify-between items-center">
              <span
                class="truncate w-[60%] text-ellipsis overflow-hidden whitespace-nowrap text-lg"
              >
                {{ chat.friend_display_name || chat.friend_username }}
              </span>

              <!-- Sent time -->
              <span
                class="italic text-sm text-gray-400 font-thin"
                v-if="chat.last_msg_sent_at"
              >
                {{ formatTime(chat.last_msg_sent_at) }}
              </span>
            </div>

            <p
              class="line-clamp-1 text-gray-400"
              :class="
                !chat.last_msg_seen ? 'font-bold text-gray-900' : 'font-italic'
              "
            >
              <span v-if="chat.last_message">
                {{
                  userToken.id !== chat.last_msg_sender_id
                    ? chat.last_message_sender_display_name ||
                      chat.last_message_sender_name
                    : "You"
                }}

                <span> : {{ chat.last_message }} </span>
              </span>
              <span v-else class="font-italic text-gray-400"
                >No messages yet</span
              >
            </p>
          </div>
        </li>
      </ul>

      <div v-else class="text-center text-sm mt-20">
        {{
          searchValue
            ? "Таны хайлт хоосон байна."
            : "Танд чат түүх үүсээгүй байна."
        }}
      </div>
    </div>
  </ChatContainer>
</template>

<script>
import { ref, onMounted, computed, watch } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ChatsPage",
  setup() {
    const store = useStore();
    const router = useRouter();

    const userToken = computed(() => store.getters["chat/getUser"]);
    const chats = computed(() => store.getters["chat/getChats"]);
    const lastMsg = computed(() => store.getters["chat/getLastMsg"]);

    const searchValue = ref("");

    const fetchChats = async (newSearchTerm) => {
      try {
        // isLoading.value = true;
        await store.dispatch("chat/fetchChats", {
          id: userToken.value.id,
          searchVal: newSearchTerm,
        });
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        // isLoading.value = false;
      }
    };

    onMounted(() => {
      fetchChats();

      if ("serviceWorker" in navigator) {
        navigator.serviceWorker.addEventListener("message", async (event) => {
          if (event.data.payload) {
            await store.dispatch("chat/fetchChats", {
              id: userToken.value.id,
            });
          }
        });
      }
    });

    watch(searchValue, (newSearchTerm) => {
      fetchChats(newSearchTerm);
    });

    watch(
      chats.value,
      (newChats) => {
        if (newChats) fetchChats();
      },
      { deep: true }
    );

    const selectChat = async (user) => {
      try {
        const res = await store.dispatch("chat/fetchOrCreateChat", {
          myId: userToken.value.id,
          friendId: user.friend_id,
        });

        if (res.message == "success") {
          router.push({
            name: "MessagePage",
            params: { id: res?.chatId },
            query: { name: user.friend_username },
          });
        }
      } catch (error) {
        console.error("Error fetching users:", error);
      }
    };

    const goBackToMenu = () => {
      router.push({ path: "/", hash: "#menu" });
    };

    const formatTime = (timestamp) => {
      if (!timestamp) return "Invalid time";

      try {
        const date = new Date(timestamp);
        if (isNaN(date)) throw new Error();

        const now = new Date();
        const isToday =
          date.getDate() === now.getDate() &&
          date.getMonth() === now.getMonth() &&
          date.getFullYear() === now.getFullYear();

        if (isToday) {
          // Show only the time if the message is from today
          return new Intl.DateTimeFormat("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(date);
        } else {
          // Show full date and time if the message is from another day
          return new Intl.DateTimeFormat("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }).format(date);
        }
      } catch {
        return "Invalid time";
      }
    };

    return {
      chats,
      searchValue,
      selectChat,
      lastMsg,
      goBackToMenu,
      userToken,
      formatTime,
    };
  },
};
</script>
