<template>
  <div
    class="fixed left-2 bottom-20 sm:left-6 bg-gray-100 shadow-2xl rounded-lg p-4 space-y-4 w-96 z-[9999]"
    v-if="showAlert"
  >
    <!-- Notification Icon -->
    <div class="flex gap-3 items-center">
      <div class="bg-blue-500 text-white py-2 px-3 rounded-lg">
        <font-awesome-icon icon="fa-bell" />
      </div>

      <div class="space-y-1 text-sm">
        <h3 class="font-semibold text-gray-800">Subscribe now.</h3>
        <p class="text-gray-600">
          You can disable notifications anytime from your browser.
        </p>
      </div>
    </div>

    <!-- Buttons -->
    <div class="flex gap-8 justify-end text-sm">
      <button
        class="text-blue-500 hover:text-blue-700 font-medium"
        @click="dismissAlert"
      >
        Later
      </button>
      <button
        class="bg-blue-500 text-white px-3 py-2 rounded-md hover:bg-blue-600"
        @click="subscribe"
      >
        Subscribe
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: "AskPermissionAlert",
  props: { showAlert: { type: Boolean, required: true } },
  emits: ["update:showAlert", "subscribe"],

  setup(props, { emit }) {
    const dismissAlert = () => {
      emit("update:showAlert", false); // Hide alert when user clicks "Later"
    };

    const subscribe = () => {
      emit("subscribe"); // Notify parent that the user has subscribed
      emit("update:showAlert", false); // Hide alert after subscribing
    };

    return { dismissAlert, subscribe };
  },
};
</script>
