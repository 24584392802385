<template>
  <div class="flex justify-center items-center h-screen bg-brand">
    <div
      class="w-full sm:w-1/2 h-72 flex flex-col gap-6 justify-center items-center text-primary font-bold m-4 shadow-lg bg-gray-50"
    >
      <span>Sign In to Gate 5 chat</span>

      <div id="buttonDiv"></div>
    </div>
  </div>
</template>

<script>
import { onMounted } from "vue";
import { useStore } from "vuex";
import Cookies from "js-cookie";

export default {
  name: "ChatLogin",
  setup() {
    const store = useStore();

    const handleCredentialResponse = async (response) => {
      try {
        const res = await store.dispatch("chat/login", {
          idToken: response.credential,
        });

        setTokenAndRedirect(res.token);
      } catch (e) {
        console.error(e);
      }
    };

    const initializeGoogleSignIn = () => {
      window.google.accounts.id.initialize({
        client_id:
          "490593174633-43qpekcbffkh721e8evi1emq4bh8scuf.apps.googleusercontent.com",
        callback: handleCredentialResponse,
      });
      window.google.accounts.id.renderButton(
        document.getElementById("buttonDiv"),
        {
          theme: "outline",
          size: "large",
        }
      );
      window.google.accounts.id.prompt();
    };

    onMounted(() => {
      initializeGoogleSignIn();
    });

    const setTokenAndRedirect = (token) => {
      const expirationDate = new Date();
      expirationDate.setTime(expirationDate.getTime() + 3 * 60 * 60 * 1000);

      Cookies.set("GBtoken", token, { expires: expirationDate });

      if (token) window.location.href = "/chat/profile";
    };

    return {
      handleCredentialResponse,
      initializeGoogleSignIn,
    };
  },
};
</script>
