<template>
  <div>
    <!-- Show chat details -->
    <ChatContainer v-if="!showImg">
      <div class="bg-gray-50 p-4 border-b border-gray-100 relative">
        <button class="py-1 px-3 rounded-lg absolute" @click="goBack">
          <font-awesome-icon icon="fa-chevron-left" class="text-gray-400" />
        </button>
        <p class="text-center text-xl font-semibold">Details</p>
      </div>

      <div class="sm:w-96 bg-white pt-8" v-if="chatFriendDetails">
        <!-- Profile Section -->
        <div class="flex gap-4 px-4">
          <div @click="showImg = true" v-if="chatFriendDetails.profile_picture">
            <img
              :src="chatFriendDetails.profile_picture"
              alt="Profile Image"
              class="w-20 h-20 rounded-lg object-cover cursor-pointer"
            />
          </div>

          <div class="space-y-1">
            <div class="flex gap-3 items-center text-gray-700">
              <h1 class="text-xl font-bold">
                {{ chatFriendDetails.username }}
              </h1>
              <font-awesome-icon icon="fa-user-tie" class="text-blue-900" />
            </div>
            <p v-if="chatFriendDetails.display_name">
              Username: {{ chatFriendDetails.display_name }}
            </p>
          </div>
        </div>

        <!-- More Information -->
        <div class="mt-6">
          <p class="p-4 font-bold">More</p>
          <div class="space-y-2">
            <div
              class="bg-gray-50 hover:bg-gray-100 flex justify-between items-center p-4"
            >
              <p>Relationship status</p>
              <p>{{ chatFriendDetails.relationship_status || "Хоосон" }}</p>
            </div>
            <div
              class="bg-gray-50 hover:bg-gray-100 flex justify-between items-center p-4"
            >
              <p>Table number</p>
              <p>{{ chatFriendDetails.table_number || "Хоосон" }}</p>
            </div>
          </div>
        </div>
      </div>
    </ChatContainer>

    <div v-else class="bg-brand sm:flex sm:justify-center">
      <!-- Show image fullscreen -->
      <div class="sm:w-96 bg-gray-50">
        <div
          class="p-4 bg-gray-50 fixed top-0 left-0 right-0 z-10 w-full sm:w-96 mx-auto"
        >
          <button
            class="py-1 px-3 rounded-lg absolute"
            @click="showImg = false"
          >
            <font-awesome-icon icon="fa-chevron-left" class="text-gray-400" />
          </button>
          <p class="text-center text-xl font-semibold">Profile picture</p>
        </div>

        <div
          class="flex justify-center items-center h-screen"
          v-if="chatFriendDetails.profile_picture"
        >
          <img
            :src="chatFriendDetails.profile_picture"
            alt="Profile Image"
            class="w-full h-[calc(100vh-100px)] object-contain pt-10"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

export default {
  name: "ShowUserDetails",
  setup() {
    const router = useRouter();
    const route = useRoute();
    const store = useStore();

    const id = route.params.id;

    const showImg = ref(false);
    const chatFriendDetails = ref([]);

    onMounted(async () => {
      try {
        // isLoading.value = true;
        const res = await store.dispatch("chat/fetchProfile", { id: id });
        chatFriendDetails.value = res.profile;
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        // isLoading.value = false;
      }
    });

    const goBack = () => {
      router.go(-1);
    };

    return { goBack, showImg, chatFriendDetails };
  },
};
</script>
